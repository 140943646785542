import { cn } from '@/helpers/tailwind';
import { Typography } from '@/atoms/00-ui/Typography';
import { IPhaseCardProps } from '@/molecules/00-ui/PhaseCard/index.types';

export const PhaseCard = ({
  preTitle,
  title,
  list,
  wrapperClassName,
  themeColor = 'white',
  cardTitleComponent = 'h4',
}: IPhaseCardProps) => {
  return (
    <div
      className={cn(
        `flex flex-col relative group
        py-[40px] px-[24px] 
        w-full max-w-[300px] mobile:max-w-[410px] 
        min-h-[425px] mobile:min-h-[583px] 
        rounded-[20px] border-[1px] border-black-4/30 shadow-lg 
        mobile:bg-[length:446px_583px]
        `,
        {
          ['bg-linear-gradient-grey-blue']: themeColor === 'white',
          ['bg-linear-gradient-pink-dark-pink']: themeColor === 'pink',
          [`${wrapperClassName}`]: wrapperClassName,
        },
      )}
    >
      <Typography
        variant="preTitle"
        component="p"
        className={cn(`text-xs mobile:text-base pb-[24px] font-normal`, {
          ['text-green-8']: themeColor === 'white',
          ['text-green-7']: themeColor === 'pink',
        })}
      >
        {preTitle}
      </Typography>

      <div
        className="
          duration-500
          overflow-hidden
          transition-[height,flex-grow,flex-shrink]
          grow-[1] group-hover:grow-0 group-hover:shrink-[1]
          basis-[0px] group-hover:h-[0px]
          delay-500 group-hover:delay-0
          will-change-transform
        "
      >
        <Typography
          variant="h2"
          component={cardTitleComponent}
          className={cn(`text-2xl mobile:text-[36px] leading-[29px] mobile:leading-[43px] tracking-[-0.4px]`, {
            ['text-black-9']: themeColor === 'white',
            ['text-white']: themeColor === 'pink',
          })}
        >
          {title}
        </Typography>
      </div>

      <div
        className="
          flex w-full relative
          grow-0 group-hover:grow-[1]
          group-hover:shrink-[0]
          transition-[flex-grow,flex-shrink] duration-500
          will-change-transform
        "
      >
        <div className="w-full">
          <hr
            className={cn(`w-full pb-[20px] mobile:pb-[28px]`, {
              ['border-purple-5/50']: themeColor === 'white',
              ['border-black-4/30']: themeColor === 'pink',
            })}
          />
          <ul className="flex flex-col gap-[20px]">
            {list.map((item) => (
              <li
                key={item.title}
                className="flex flex-col"
              >
                <div className="flex items-center gap-[10px]">
                  <div
                    className={cn(
                      `w-[13px] mobile:w-[15px] h-[19.5px] self-start mt-[2px]
                       bg-center bg-no-repeat bg-contain
                      shrink-0 will-change-transform`,
                      {
                        ['bg-[url(/icons/arrow-pink.svg)]']: themeColor === 'white',
                        ['bg-[url(/icons/arrow-gray.svg)]']: themeColor === 'pink',
                      },
                    )}
                  />
                  <Typography
                    variant="body1"
                    component="h3"
                    className={cn(`font-medium text-base mobile:text-xl will-change-transform]`, {
                      ['text-black-9']: themeColor === 'white',
                      ['text-white']: themeColor === 'pink',
                    })}
                  >
                    {item.title}
                  </Typography>
                </div>

                <div
                  className="
                    grid transition-[grid-template-rows]
                    duration-500 group-hover:delay-500
                    grid-rows-[0fr] group-hover:grid-rows-[1fr]
                    will-change-transform
                  "
                >
                  <div className="overflow-hidden">
                    <Typography
                      variant="body1"
                      component="p"
                      className={cn(
                        `text-[10px] mobile:text-sm
                        leading-base mobile:leading-[22px]
                        tracking-[-0.4px] pt-[12px]`,
                        {
                          ['text-black-9']: themeColor === 'white',
                          ['text-white']: themeColor === 'pink',
                        },
                      )}
                    >
                      {item.description}
                    </Typography>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
