import { Typography } from '@/atoms/00-ui/Typography';
import { cn } from '@/helpers/tailwind';
import { IJourneyTabsProps } from '@/molecules/00-ui/JourneyTabs/index.types';

export const JourneyTabs = ({ tabs, activeIndex, setActiveIndex, themeColor = 'pink' }: IJourneyTabsProps) => {
  return (
    <div
      className={cn(`relative border-t-[1px]`, {
        ['border-purple-4/50']: themeColor === 'pink',
        ['border-black-4']: themeColor === 'white',
      })}
    >
      <ul className="w-full flex justify-between gap-base relative top-[-1px]">
        {tabs.map((tab, index) => {
          const isActive = activeIndex === index;

          return (
            <li
              key={tab}
              onClick={() => setActiveIndex(index)}
              className={cn(
                `
                py-[12px] border-t-[2px] border-transparent group
                transition-colors duration-300
              `,
                {
                  ['border-purple-7']: isActive && themeColor === 'pink',
                  ['border-green-7']: isActive && themeColor === 'white',
                  ['cursor-pointer']: !isActive,
                },
              )}
            >
              <Typography
                variant="preTitle"
                component="span"
                className={cn(`leading-[24px] transition-colors duration-300 text-xs lg-2:text-base font-normal`, {
                  ['text-purple-7']: isActive && themeColor === 'pink',
                  ['group-hover:text-purple-5 text-black-10']: !isActive && themeColor === 'pink',
                  ['text-green-7']: isActive && themeColor === 'white',
                  ['group-hover:text-green-6 text-black-4']: !isActive && themeColor === 'white',
                })}
              >
                {tab}
              </Typography>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
