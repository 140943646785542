import { cn } from '@/helpers/tailwind';
import { Typography } from '@/atoms/00-ui/Typography';
import { IJourneyTilesProps } from '@/molecules/03-decorations/TilesStack/index.types';
import { useState } from 'react';
import './index.styles.scss';

export const TilesStack = ({ list, activeIndex, setActiveIndex, themeColor = 'pink' }: IJourneyTilesProps) => {
  const [hoverIndex, setHoverIndex] = useState<null | number>(null);

  return (
    <div className="flex flex-col justify-center items-center flex-1 relative">
      {list.map((item, index) => {
        const isActive = index === activeIndex;

        return (
          <div
            key={item}
            className={cn(
              `w-full max-w-[617.1px] 
               max-h-[380.6px] aspect-[617/380] 
               absolute left-1/2 translate-x-[-50%] 
               group pointer-events-none
              `,
              {
                [`top-0 mobile-landscape:top-[45px] z-[4]`]: index === 0,
                [`top-[15px] mobile-landscape:top-[90px] z-[3]`]: index === 1,
                [`top-[30px] mobile-landscape:top-[135px] z-[2]`]: index === 2,
                [`top-[45px] mobile-landscape:top-[180px] z-[1]`]: index === 3,
              },
            )}
          >
            <div
              className={cn(
                `w-full h-full absolute inset-0
                  bg-center bg-no-repeat bg-contain
                  duration-500 will-change-transform
                `,
                {
                  [`bg-[url(/shapes/tile-pink.svg)]`]: themeColor === 'pink',
                  [`bg-[url(/shapes/tile-black.svg)]`]: themeColor === 'black',
                  [`translate-x-[37.5px] mobile-landscape:translate-x-[75px] 
                    translate-y-[22.5px] mobile-landscape:translate-y-[45px]`]: isActive,
                  [`translate-x-[18.75px] mobile-landscape:translate-x-[37.5px] 
                    translate-y-[11.25px] mobile-landscape:translate-y-[22.5px]`]: hoverIndex === index && !isActive,
                },
              )}
            />
            <div
              className={cn(
                `w-full h-full absolute inset-0
                bg-[url(/shapes/tile-purple-gradient.svg)] bg-center bg-no-repeat bg-contain
                opacity-0 duration-500 will-change-transform
              `,
                {
                  [`translate-x-[37.5px] mobile-landscape:translate-x-[75px] 
                    translate-y-[22.5px] mobile-landscape:translate-y-[45px] opacity-[100]`]: isActive,
                  [`translate-x-[18.75px] mobile-landscape:translate-x-[37.5px] 
                    translate-y-[11.25px] mobile-landscape:translate-y-[22.5px]`]: hoverIndex === index && !isActive,
                },
              )}
            >
              <div className="relative top-[3.5%] left-1/2 translate-x-[-50%] w-[52.3%]">
                <div
                  className="
                    relative z-[10] aspect-[1/1] text-white
                    matrix-rotate rounded-[10px] flex items-end
                    p-[8px] mobile-landscape:p-[20px]
                  "
                >
                  <Typography
                    variant="preTitle"
                    component="span"
                    className="text-white text-[8px] mobile-landscape:text-base"
                  >
                    {item}
                  </Typography>
                </div>

                <div
                  onMouseOver={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={() => setActiveIndex(index)}
                  className="
                    absolute w-full z-[10] aspect-[1/1] text-white left-0
                    bottom-[-8px] mobile-landscape:bottom-[-16px]
                    matrix-rotate pointer-events-auto cursor-pointer
                  "
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
