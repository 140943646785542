import { Typography } from '@/atoms/00-ui/Typography';
import { cn } from '@/helpers/tailwind';
import { IStickerTabProps } from '@/molecules/00-ui/StickerTabs/index.types';

export const StickerTabs = ({ list, setActiveIndex, activeIndex, themeColor = 'white' }: IStickerTabProps) => {
  return (
    <ul className="flex flex-col items-start h-full h-[583px]">
      {list.map((phase, index) => {
        const isActive = activeIndex === index;

        return (
          <li
            key={phase}
            onClick={() => setActiveIndex(index)}
            className={cn(`flex-1 relative group`, {
              [`z-[10]`]: isActive,
              [`cursor-pointer`]: !isActive,
            })}
          >
            <div
              className={cn(
                `h-full flex items-center
                px-[7px] mobile:px-[9px]
                border-[1px] border-l-0 rounded-br-[20px] rounded-tr-[20px]
                border-black-4/30 bg-[length:446px_583px]
                `,
                {
                  [`bg-linear-gradient-grey-blue`]: (themeColor === 'white' && isActive) || themeColor === 'white',
                  [`bg-linear-gradient-pink-dark-pink`]: themeColor === 'black' && isActive,
                  [`bg-blue-2`]: themeColor === 'black' && !isActive,
                },
              )}
              style={{
                backgroundPosition: `right -${(index * 583) / list.length}px`,
              }}
            >
              <Typography
                variant="preTitle"
                component="p"
                className={cn(
                  `[writing-mode:vertical-lr] rotate-180 transition-colors 
                  leading-xs mobile:leading-base
                  text-xs mobile:text-base
                `,
                  {
                    [`text-green-8`]: themeColor === 'white' && isActive,
                    [`text-purple-3 group-hover:text-green-6`]: themeColor === 'white' && !isActive,
                    [`text-green-7`]: themeColor === 'black' && isActive,
                    [`text-black-6 group-hover:text-green-6/70`]: themeColor === 'black' && !isActive,
                  },
                )}
              >
                {phase}
              </Typography>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
