'use client';

import { useEffect, useRef, useState } from 'react';
import { cn } from '@/helpers/tailwind';
import { IJourneySectionProps } from '@/organisms/01-sections/JourneySection/index.types';
import { PhaseCard } from '@/molecules/00-ui/PhaseCard';
import { Typography } from '@/atoms/00-ui/Typography';
import { StickerTabs } from '@/molecules/00-ui/StickerTabs';
import { TilesStack } from '@/molecules/03-decorations/TilesStack';
import { JourneyTabs } from '@/molecules/00-ui/JourneyTabs';
import { useIsInViewport } from '@/hooks/useIsInViewport';
import { ANIMATION_DELAY } from '@/organisms/01-sections/JourneySection/index.constants';

export const JourneySection = ({
  titleSlot,
  phases,
  themeColor = 'light',
  innerCardsTitleComponent = 'h4',
}: IJourneySectionProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [hasInitialAnimated, setInitialAnimated] = useState(false);
  const ref = useRef(null);
  const isInViewPort = useIsInViewport(ref);

  useEffect(() => {
    if (isInViewPort && !hasInitialAnimated) {
      setTimeout(() => {
        setInitialAnimated(true);
      }, ANIMATION_DELAY);
    }
  }, [isInViewPort, hasInitialAnimated]);

  return (
    <div
      className="flex flex-col gap-y-[40px]"
      ref={ref}
    >
      {titleSlot}
      <div
        className="
          w-full flex flex-col
          mobile-landscape:flex-row justify-between lg:justify-center lg-2:justify-between items-center
          gap-y-[40px] gap-x-[80px] max-w-[1184px] self-center
        "
      >
        <div
          className="
            w-full flex gap-x-[60px]
            justify-center mobile-landscape:justify-start
            mobile-landscape:ml-[-30px]
            lg:hidden lg-2:flex
          "
        >
          <div
            aria-hidden="true"
            className="
              h-[150px] mobile-landscape:h-auto mobile-landscape:min-h-[583px]
              w-[200px] mobile-landscape:w-full mobile-landscape:max-w-[617.1px] shrink-[0.3]
            "
          >
            <TilesStack
              activeIndex={hasInitialAnimated ? activeIndex : null}
              list={phases.map((phase) => phase.tileTitle)}
              setActiveIndex={setActiveIndex}
              themeColor={themeColor === 'dark' ? 'black' : 'pink'}
            />
          </div>

          <ul className="flex flex-col gap-base items-end mobile-landscape:hidden">
            {phases.map((phase, index) => {
              const isActive = activeIndex === index;

              return (
                <li
                  key={`${phase.tileTitle}-mobile`}
                  onClick={() => setActiveIndex(index)}
                  className="group"
                >
                  <Typography
                    variant="preTitle"
                    component="p"
                    className={cn(`text-[10px] text-right`, {
                      ['text-black-10']: themeColor === 'light',
                      ['text-purple-7']: themeColor === 'light' && isActive,
                      ['group-hover:text-purple-5 group-hover:cursor-pointer']: themeColor === 'light' && !isActive,
                      ['text-black-4']: themeColor === 'dark',
                      ['text-green-7']: themeColor === 'dark' && isActive,
                      ['group-hover:text-green-6 group-hover:cursor-pointer']: themeColor === 'dark' && !isActive,
                    })}
                  >
                    {phase.tileTitle}
                  </Typography>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="flex relative justify-end shrink-0">
          <div
            role="presentation"
            className="
              bg-[url(/backgrounds/lens-purple-1.svg)] mobile-landscape:hidden
              bg-contain bg-center bg-no-repeat w-[900px] h-[700px]
              absolute right-[-420px] top-[-125px] pointer-events-none
            "
          />

          {phases.map((phase, index) => (
            <div
              key={phase.title}
              className={cn(`relative z-[10] hidden`, {
                ['block']: activeIndex === index,
              })}
            >
              <div
                className={cn(
                  `absolute inset-0 w-full h-full
                  rounded-tl-[20px] rounded-bl-[20px]
                  border-y-[1px] border-black-4/30
                  bg-[length:446px_583px]`,
                  {
                    ['bg-linear-gradient-grey-blue']: themeColor === 'light',
                    ['bg-blue-2']: themeColor === 'dark',
                  },
                )}
              />
              <PhaseCard
                preTitle={`PHASE ${phase.number}: ${phase.tileTitle}`}
                title={phase.title}
                list={phase.list}
                wrapperClassName={cn(``, {
                  [`rounded-tr-[0px]`]: activeIndex === 0,
                  [`rounded-br-[0px]`]: activeIndex === phases.length - 1,
                })}
                themeColor={themeColor === 'dark' ? 'pink' : 'white'}
                cardTitleComponent={innerCardsTitleComponent}
              />
            </div>
          ))}

          <div className="relative left-[-1px]">
            <StickerTabs
              list={phases.map((phase) => `PHASE ${phase.number}`)}
              setActiveIndex={setActiveIndex}
              activeIndex={activeIndex}
              themeColor={themeColor === 'dark' ? 'black' : 'white'}
            />
          </div>
        </div>
      </div>

      <div className="w-full pt-[40px] hidden mobile-landscape:block">
        <JourneyTabs
          tabs={phases.map((phase) => phase.tileTitle)}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
          themeColor={themeColor === 'dark' ? 'white' : 'pink'}
        />
      </div>
    </div>
  );
};
